import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";

interface CSSContextProps {
  modeClass: "darkTheme" | "lightTheme";
  modeClass2: string;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

const CSSContext = createContext<CSSContextProps | undefined>(undefined);

interface CSSProviderProps {
  children: ReactNode;
}

// CONTEXT USED IN ALL THE COMPONENTS TO AVOID PROPS DRILLING

export const CSSProvider: React.FC<CSSProviderProps> = ({ children }) => {
  const [modeClass, setModeClass] = useState<"lightTheme" | "darkTheme">(
    "darkTheme"
  );
  const [modeClass2, setModeClass2] = useState<string>("darkTheme2");
  const [darkMode, setDarkMode] = useState<boolean>(true);

  useEffect(() => {
    setModeClass(darkMode ? "darkTheme" : "lightTheme");
    setModeClass2(darkMode ? "darkTheme2" : "lightTheme2");
  }, [darkMode]);

  return (
    <CSSContext.Provider
      value={{
        modeClass,
        modeClass2,
        darkMode,
        setDarkMode,
      }}
    >
      {children}
    </CSSContext.Provider>
  );
};

export const useCSSContext = () => {
  const context = useContext(CSSContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
